import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './SandboxInvestigator.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { fetchFromApi } from '../utils/api';

const SandboxInvestigator = () => {
  const [guid, setGuid] = useState('');
  const [isFetchingText, setIsFetchingText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsText, setItemsText] = useState('');
  const [promptText, setPromptText] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLookupText = async (guid: any) => {
      setIsFetchingText(true);
      try {
        const response = await fetchFromApi(`/lookup/${guid}`);
        if (!response.ok) throw new Error('Failed to fetch lookup details');
        if (response.status === 200) {
          const data = await response.json();
          setIsFetchingText(false);
          setItemsText(data.text);
          setPromptText(data.prompt);
        } else {
          setIsFetchingText(false);
          setError('Failed to fetch meetup URL');
        }
      } catch (error) {
        setIsFetchingText(false);
        console.error('Error fetching from API:', error);
        setError('An error occurred while fetching data.');
      }
    };

    // Extract the GUID from the URL
    const path = window.location.pathname; // e.g., "/lookup/12345"
    const match = path.match(/\/lookup\/(.+)/); // Regex to extract GUID
    if (match && match[1]) {
      console.log('fetching lookup text for guid:', match[1]);
      fetchLookupText(match[1]);
    } else {
      console.log('no guid found in URL');
    }
  }, []); // Empty dependency array means this runs once on mount


  const generateGUID = () => {
    // Generate current timestamp in yyyymmddhhmmss format
    const now = new Date();
    const timestamp = now.getFullYear().toString() +
      (now.getMonth() + 1).toString().padStart(2, '0') +
      now.getDate().toString().padStart(2, '0')

    // Generate a random sequence of 8 hexadecimal characters
    const randomSequence = 'xxxxxxxx'.replace(/[x]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      return r.toString(16);
    });

    // Combine the timestamp with the random sequence
    return `${timestamp}-${randomSequence}`;
  };

  const initiateSearch = async () => {
    if (itemsText === '') {
      setError('');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const newGuid = generateGUID();
    try {
      navigate(`/lookup/${newGuid}`);
      const itemsArray = itemsText.split('\n')
      .filter(row => row.trim() !== '')
      .map((rowText, index) => {
        let key = rowText.toLowerCase()
          .replace(/[^a-z0-9]/g, '-') // Replace any character that is not a lowercase letter or number with '-'
          .replace(/-+/g, '-') // Replace consecutive '-' with a single '-'
          .slice(0, 64); // Ensure the key is no longer than 64 characters
        key = key.replace(/^-+|-+$/g, '');
        return {
          text: rowText,
          name: rowText,
          key: key
        };
      });
      const response = await fetchFromApi('/scrape', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          guid: newGuid,
          name: newGuid,
          text: itemsText,
          items: itemsArray,
          prompt: promptText,
        }),
      });
      if (response.status === 200) {
        setIsLoading(false);
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to start scraping');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching from API:', error);
      setError('An error occurred while fetching data.');
      setIsLoading(false);
    }
  };

  return (
    <header className="App-header text-center">
      <h1 className={styles.title}><img src="/logo512.png" width={36} style={{ position: 'relative', top: '-4px' }} /> Search Sandbox</h1>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-8">
            <span className={styles.textAreaCaption}>
            List of items
            </span>
            <textarea
              className={`form-control ${error ? 'is-invalid' : ''} ${styles.textAreaCustom}`}
              placeholder={isFetchingText ? "Loading..." : "John Doe, john.doe@gmail.com, Oregon\nJane Smith, jane.smith@hotmail.com, N/A\nJack Johnson, jack@hello.com, Sweden\n..."}
              aria-label="List of people"
              aria-describedby="button-addon2"
              rows={4}
              value={itemsText}
              onChange={(e) => setItemsText(e.target.value)}
            ></textarea>
            <span className={styles.textAreaCaption}>
              What do you want to search for?
            </span>
            <input
                type="text"
                className={`form-control ${error ? 'is-invalid' : ''} ${styles.textAreaCustom}`}
                placeholder={"Title and company"}
                aria-label="Meetup URL"
                aria-describedby="button-addon2"
                value={promptText}
                onChange={(e) => setPromptText(e.target.value)}
              />
            <button
              className="btn btn-outline-secondary"
              style={{ width: '100%', marginTop: '20px' }}
              type="button"
              id="button-addon2"
              disabled={isLoading}
              onClick={initiateSearch}
            >
              Search
            </button>
            <div style={{ minHeight: '50px' }}>
              {error && (
                <div className="invalid-feedback" style={{ display: 'block' }}>{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SandboxInvestigator;
