import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchFromApi, fetchFromS3 } from '../utils/api';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// Assuming fetchFromS3 is properly typed in your utils/api file
// Define an interface for attendee information
interface Item {
  guid: string;
  key: string;
  name: string;
  columns: string[];
  values: string[];
}

// Define an interface for the meetup details
interface LookupDetails {
  guid: string;
  name: string;
  date: string;
  text: string;
  description: string;
  scrapedItems: number;
  totalItems: number;
  lastModified: string;
  ageSeconds: number;
  items: Record<string, string>; // Map of items key to signed URL
}

const Lookup = () => {
  const { guid } = useParams<{ guid: string }>();
  const [lookupDetails, setLookupDetails] = useState<LookupDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [itemsData, setItemsData] = useState<Record<string, Item>>({});
  const [loadingStatus, setLoadingStatus] = useState<string>('loading');
  const [fetchAttempt, setFetchAttempt] = useState<number>(0);

  // If guid changes, reset the state
  useEffect(() => {
    setLookupDetails(null);
    setIsLoading(true);
    setError('');
    setItemsData({});
    setLoadingStatus('loading');
    setFetchAttempt(0);
  }, [guid]);

  useEffect(() => {
    const fetchMeetupDetails = async () => {
      if (loadingStatus === 'done' || fetchAttempt >= 100) {
        setIsLoading(false); // Stop loading if done or max attempts reached
        return;
      }

      try {
        const response = await fetchFromApi(`/lookup/${guid}`);
        if (!response.ok) throw new Error('Failed to fetch lookup details');
        const data: LookupDetails = await response.json();
        setLookupDetails(data);
        // Determine the loading status based on the response data
        if (data.name === '') {
          setLoadingStatus('crawling-lookup');
        } else if (data.scrapedItems === 0) {
          setLoadingStatus('crawling-items-started');
        } else if (data.scrapedItems > 0 && data.scrapedItems < data.totalItems) {
          setLoadingStatus('crawling-items');
          fetchItemDetails(data.items);
        } else if (data.scrapedItems === data.totalItems) {
          setLoadingStatus('done');
          fetchItemDetails(data.items);
          setIsLoading(false);
        }
      } catch (err: any) {
        setError(err.message);
        setIsLoading(false);
      }

      setFetchAttempt(fetchAttempt + 1);
    };

    // Set an interval to fetch details every 3 seconds
    const intervalId = setInterval(() => {
      fetchMeetupDetails();
    }, 3000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [guid, fetchAttempt, loadingStatus]); // Include fetchAttempt and loadingStatus in the dependency array

  const fetchItemDetails = async (items: Record<string, string>) => {
    const itemKeys = Object.keys(items);
    itemKeys.forEach(async (itemKey) => {
      if (!itemsData[itemKey]) {
        try {
          const url = items[itemKey];
          const response = await fetchFromS3(url);
          if (!response.ok) throw new Error(`Failed to fetch data for ${itemKey}`);
          const attendeeInfo: Item = await response.json();
          setItemsData(prevData => ({ ...prevData, [itemKey]: attendeeInfo }));
        } catch (err: any) {
          // console.log(attendeeKey)
          // console.error(err.message);
        }
      }
    });
  };

  const progressValue = lookupDetails ? (lookupDetails.ageSeconds < 180 ? lookupDetails.scrapedItems / lookupDetails.totalItems : 1) : 0;

  const meetupDetailsTable = lookupDetails && <table className="table mb-4">
    <tbody>
      <tr>
        <th style={{ textAlign: 'left' }}>Progress</th>
        <td style={{ textAlign: 'left' }}>
          <div className="progress" style={{ width: '100%', height: '20px' }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(progressValue) * 100}%` }}
              aria-valuenow={lookupDetails.scrapedItems}
              aria-valuemin={0}
              aria-valuemax={lookupDetails.totalItems}>
              {Math.round((progressValue) * 100)}%
            </div>
          </div>
          {lookupDetails.ageSeconds < 120 && <div style={{ marginTop: '5px', color: '#aaa' }}>
            {loadingStatus !== 'done' && fetchAttempt < 100 && lookupDetails.ageSeconds < 120 && <span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px', color: '#aaa' }}></span>}
            {lookupDetails.scrapedItems} / {lookupDetails.totalItems} items crawled
          </div>}
          {lookupDetails.ageSeconds >= 120 && <div style={{ marginTop: '5px', color: '#aaa' }}>
            {lookupDetails.totalItems} / {lookupDetails.totalItems} items crawled
          </div>}
        </td>
      </tr>
    </tbody>
  </table>

  const firstItem = Object.values(itemsData)[0];
  const attendeesTable = firstItem ? <>
    <table className="table">
      <thead className="thead-light">
        <tr>
        {firstItem.columns.map((column, index) => (
            <th key={index} style={{ textAlign: 'left' }}>{column}</th>
        ))}
        </tr>
      </thead>
      <tbody>
        {Object.values(itemsData).map((item, index) => (
            <tr key={item.key}>
                {item.values.map((value, index) => (
                    <td key={index} style={{ textAlign: 'left' }}>{value}</td>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  </> : null;

  return (
    <div className="container" style={{ maxWidth: '1024px', margin: '0 auto' }}> {/* Adjusted for narrower width with more margin */}
      {error && <p className="text-danger">Error: {error}</p>}
      {loadingStatus === 'loading' && (
        <div><span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px' }}></span>Loading...</div>
      )}
      {loadingStatus === 'crawling-lookup' && (
        <>
          <div><span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px' }}></span>Initializing crawling, please wait...</div>
        </>
      )}
      {loadingStatus === 'crawling-items-started' && (
        <>
          {meetupDetailsTable}
          <div><span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: '7px' }}></span>Crawling items...</div>
        </>
      )}
      {(loadingStatus === 'crawling-items' || loadingStatus === 'done') && (
        <>
          {meetupDetailsTable}
          {attendeesTable}
        </>
      )}
      {error && <p className="text-danger">Error: {error}</p>}
    </div>
  );
};

export default Lookup;

